import "./bootstrap";
import "../css/app.css";

import React from "react";
import { createRoot } from "react-dom/client";
import { createInertiaApp } from "@inertiajs/react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { StepsTheme as Steps } from "chakra-ui-steps";
import { Ziggy } from "./ziggy";
import { AblyProvider } from "ably/react";
import { Realtime } from "ably";

export const ABLY_KEY = "dGJovA.X041Cw:8oQ_TLQq9tXXN5FVpodiV3dqBzbyF-Ki5Fjrq3vJOtk"; // Prod
// export const ABLY_KEY = "_eNh-g.qk0oLA:FEcSLFqV5XGV5XkH-OWhhZBch2xfQqPhf8vU7q7gxSs"; // Dev

window.Ziggy = Ziggy;

const theme = extendTheme({
    components: {
        Steps,
    },
});

const appName = window.document.getElementsByTagName("title")[0]?.innerText || "RobotiZap";

const ablyClient = Realtime.Promise({ key: ABLY_KEY });

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => {
        const pages = import.meta.glob("./Pages/**/*.jsx");

        if (!name) {
            return null;
        }

        return pages[`./Pages/${name}.jsx`]();
    },
    // resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        App.use;

        const root = createRoot(el);

        if (props?.initialPage?.props?.env?.production) {
            Sentry.init({
                dsn: "https://c8d179dfc74e4d2db6dce5ec4c02c7e1@o1272840.ingest.sentry.io/6466832",
                integrations: [
                    new BrowserTracing(),
                    new Sentry.Replay({
                        maskAllText: false,
                        maskAllInputs: false,
                        blockAllMedia: false,
                    }),
                ],
                tracesSampleRate: 0.25,
                replaysSessionSampleRate: 0,
                replaysOnErrorSampleRate: 1.0,
            });
        }

        return root.render(
            <ChakraProvider theme={theme}>
                
            <AblyProvider client={ablyClient}>
                <App {...(props ?? {})} />
                </AblyProvider>
            </ChakraProvider>,
        );
    },
    progress: false,
});
